<template>
    <div class="group-dialog">
        <el-dialog :title="$t('data.group.eCertGroup')" v-model="groupData.state">
            <el-form :model="groupData">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('data.group.groupName')" :label-width="formLabelWidth">
                            <el-input v-model="groupData.name" autocomplete="off" :placeholder="$t('data.group.enterGroupName')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('data.group.groupNumber')" :label-width="formLabelWidth">
                            <el-input v-model="groupData.number" autocomplete="off" :placeholder="$t('data.group.enterGroupNumber')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="groupData.state = false">{{ $t('data.public.cancel') }}</el-button>
                <el-button type="primary" @click="submitCertGroup()">{{ $t('data.public.save') }}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { certGroupsPost,certGroupsPatch } from '../../../api/index'
export default {
    name: 'groupDialog',
    props: {
        groupData: {
            type: Object,
            default: {
                state: false,
                name: '',
                number: '',
                saveType: ''
            }
        }
    },
    data(){
        return {
          dialogFormVisible: false,
          formLabelWidth: '120px'
        }
    },
    mounted(){
    },
    methods: {
        submitCertGroup(){
            let apiData = {
                name: this.groupData.name,
                number: this.groupData.number
            }
            if(this.groupData.saveType === 'create'){
                certGroupsPost({apiData}).then( res => {
                    ElMessage.success({
                        message: 'Success',
                        type: 'success'
                    });
                    this.groupData.state = false;
                    if(this.$parent.initPageData instanceof Function){
                        this.$parent.initPageData();
                    }
                })
            } else if(this.groupData.saveType === 'edie'){
                apiData = Object.assign(apiData, {id: this.groupData.id})
                certGroupsPatch({apiData}).then( res => {
                    ElMessage.success({
                        message: 'Success',
                        type: 'success'
                    });
                    this.groupData.state = false;
                    this.$parent.initPageData();
                })
            }
            
        }
    }
}
</script>