<template>
  <div>
      <div v-for="(item, index) in certContent.printList" :key="index" class="cert-view" :style="{backgroundImage: 'url(' + certContent.background + ')'}" style="page-break-after:always">

          <div class="cert-wrap">

              <el-row :gutter="20" style="margin-bottom: 50px">
                  <el-col :span="8">
                      <div class="cert-header-logo">
                      <img :src="certContent.top_left_logo">
                      </div>
                  </el-col>
                  <el-col :span="8">
                      <div class="cert-header-logo">
                      <img :src="certContent.top_center_logo">
                      </div> 
                  </el-col>
                  <el-col :span="8">
                      <div class="cert-header-logo ">
                        <div class="cert-header-qrcode">
                          <p class="cert-number">ECERT210920HKG12345678</p>
                          <img :src="certContent.qrcode">
                          <p class="cert-qrcode-name">#Name</p>
                        </div>
                      </div>
                  </el-col>
              </el-row>

              <div class="cert-content" :style="{textAlign: certContent.text_align}">
                <p class="cert-sub-title">{{certContent.course}}</p>
                <h3 class="cert-name">{{ certContent.cert_name }}</h3>
                <p class="cert-sub-title">{{ certContent.sub_title }}</p>
                <p class="cert-printed-name"><span v-if="certContent.show_printed_name">{{item.name || ''}}</span></p>
                <p class="cert-date mb-no"><span v-if="certContent.show_date">Date of birth</span></p>
                <p class="cert-nationality"><span v-if="certContent.show_nationality">Nationality</span></p>
                <p class="cert-text cert-text-desc" v-html="certContent.description_1"></p>
                <p class="cert-date">{{certContent.issue_date || ''}}</p>
                <p class="cert-text">{{ certContent.description_2 }}</p>
                <p class="cert-date">{{certContent.expiry_date || ''}}</p>
                <p class="cert-text">{{ certContent.description_3 }}</p>
              </div>

              <el-row :gutter="20">
                  <el-col :span="8">
                      <div class="cert-footer-logo" v-if="certContent.bottom_left_logo">
                      <img :src="certContent.bottom_left_logo" alt="photo">
                      <p>{{ certContent.bottom_left_name }}<br>{{ certContent.bottom_left_title }}<br>{{ certContent.bottom_left_organization }}</p>
                      </div>
                  </el-col>
                  <el-col :span="8">
                      <div class="cert-footer-logo" v-if="certContent.bottom_center_logo">
                          <img :src="certContent.bottom_center_logo" alt="photo">
                          <p>{{ certContent.bottom_center_name }}<br>{{ certContent.bottom_center_title }}<br>{{ certContent.bottom_center_organization }}</p>
                      </div>
                  </el-col>
                  <el-col :span="8">
                      <div class="cert-footer-logo" v-if="certContent.bottom_right_logo">
                          <img :src="certContent.bottom_right_logo" alt="photo">
                          <p>{{ certContent.bottom_right_name }}<br>{{ certContent.bottom_right_title }}<br>{{ certContent.bottom_right_organization }}</p>
                      </div>
                  </el-col>
              </el-row>

          </div>

          <div class="cert-watermark">
            <img src="/image/cert-watermark.png" alt="">
          </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'ImageUpload',
  props: {
      certData: Object
  },
  data(){
      return {
          certContent: this.certData
      }
  },
  mounted(){
  }
}
</script>

<style>

@media print {
  .cert-view{
    -webkit-print-color-adjust:exact;
    -moz-print-color-adjust:exact;
    -ms-print-color-adjust:exact;
    page-break-before:always;
    margin: 0 !important;
  } 
  html, body {
        height: inherit;
    }
  #certView{
    height: inherit !important;
    overflow: inherit;
  }
}

</style>

<style scoped>
@page {  
  size: 800px 1420px;
  margin-top: 0mm;
  margin-bottom: 0mm;
  margin-left: 0mm; 
  margin-right: 0mm
}

.cert-view{
  position: relative;
  background-color: #cfeeff;
  background-repeat:no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 77px;
  width: 646px;
  height: 1266px;

}
.cert-wrap{
  /* padding: 15px;
  background-color: antiquewhite; */
  z-index: 9;
}
.cert-header-logo{
  position: relative;
  height: 180px;
}
.cert-header-logo > img{
  max-width: 100%;
  max-height: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cert-header-qrcode{
  text-align: center;
  max-width: 100%;
  max-height: 180px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0%, -50%);
}
.cert-header-qrcode img{
  height: 160px;
  width: 160px;
}
.cert-header-qrcode .cert-qrcode-name{
  width:160px;
  word-wrap:break-word;
  font-size: 12px;
}
.cert-number{
  font-size: 12px;
  margin-bottom: 5px
}
.cert-content{
  text-align: center;
  padding: 0px 15px;
}
.cert-name{
  font-size: 36px;
  color: #182389;
  height: 50px;
  line-height: 50px;
  margin-bottom: 29px;
}
.cert-sub-title{
  font-size: 20px;
  color: #182389;
  margin-bottom: 29px;
  height: 26px;
  line-height: 26px;
}
.cert-printed-name{
  font-size: 28px;
  color: #454a46;
  height: 34px;
  line-height: 34px;
}
.cert-date{
  color: #454a46;
  font-size: 19px;
  height: 28px;
  line-height: 28px;
  margin-bottom: 29px;
}
.cert-nationality{
  font-size: 19px;
  color: #454a46;
  margin-bottom: 29px;
  height: 28px;
  line-height: 28px;
}

.cert-text{
  height: 81px;
  color: #182389;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 29px;
  white-space: pre;
  white-space: pre-line;
  word-wrap: break-word;
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 3; 
  overflow: hidden;
}
.cert-text-desc{
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 3; 
  overflow: hidden;
}
.cert-footer-logo{
  text-align: center;
}
.cert-footer-logo p{
  color: #182389;
  font-size: 16px;
  margin-top: 15px;
}
.cert-footer-logo img{
  width: 200px;
  height: 200px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}
.cert-watermark{
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.cert-watermark img{
  display: block
}
</style>