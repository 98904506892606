<template>
    <div class="bg-default">
        <el-button size="small" round @click="bgDefault">{{$t('data.template.defaultBackground')}}</el-button>
        <el-dialog
            title="Choose background template"
            v-model="dialogVisible"
            width="70%">

            <el-row :gutter="20">
                <el-col :span="6" v-for="(item, index) in imgList" :key="index" style="padding-bottom: 20px;">
                    <div class="item-bg" :class='[active===index ? "bgActive":""]' @click="bgClick(index)">
                        <el-image :src="item" fit="cover"></el-image>
                    </div>
                </el-col>
            </el-row>

            <template #footer>
                <span class="dialog-footer">
                <el-button type="primary" @click="confirmDefault">Confirm</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import { getBase } from '../../../api/index'
export default {
    data(){
        return {
            dialogVisible: false,
            imgList: [],
            active: null
        }
    },
    setup() {
        
    },
    mounted() {
        this.initPageData();
    },
    methods: {
        initPageData(){
            let apiData = {}
           getBase({apiData}).then( res => {
               this.imgList = res.data.cert_default_backgrounds;
          }) 
        },
        bgDefault(){
            this.dialogVisible = true;
        },
        bgClick(index){
            if(this.active === index){
                this.active = null;
            } else {
                this.active = index;
            }
        },
        confirmDefault(){
            if(this.active !== null){
                this.$emit('imageDefault', this.imgList[this.active]);
                this.dialogVisible = false;
                return;
            }
        }
    }
}
</script>
<style scoped>
.item-bg{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.item-bg:hover{
    box-shadow: 0 0px 10px 0 rgba(64,158,255,1);
}

.bgActive{
    box-shadow: 0 0px 10px 0 rgba(64,158,255,1);
}

.item-bg .el-image{
    width: 100%;
    height: 100%;
}
</style>