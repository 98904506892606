<template>
    <!-- @selection-change="handleSelectionChange"
        :data="gridData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.account.toLowerCase().includes(search.toLowerCase()))" -->
    <el-button @click="openUserDialog()">{{ $t('data.template.searchUserEmail') }}</el-button>
    <el-dialog title="Add User" v-model="dialogTableVisible">
        <el-table 
        @selection-change="handleSelectionChange"
        :data="gridData"
        ref="multipleTable"
        tooltip-effect="dark"
        >
            <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column
            :label="$t('data.template.table.photo')"
            width="80">
                <template #default="scope">
                <img :src="scope.row.avatar" alt="" class="table-user-photo">
                </template>
            </el-table-column>
            <el-table-column property="name" :label="$t('data.template.table.userName')"></el-table-column>
            <el-table-column property="email" :label="$t('data.template.table.eMail')"></el-table-column>
            <el-table-column
            width="200">
                <template #header>
                    <el-input
                    class="input-border"
                    @change="searchEmail"
                    v-model="search"
                    size="mini"
                    :placeholder="$t('data.template.table.searchUserEmail')"/>
                </template>
            </el-table-column>
        </el-table>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogTableVisible = false">{{$t('data.public.cancel')}}</el-button>
                <el-button type="primary" @click="confirm()">{{$t('data.public.confirm')}}</el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script>
import { membersList } from '../../../api/index'
export default {
    name: 'search user',
    emits: ['userList'],
    data() {
        return {
            dialogTableVisible: false,
            gridData: [],
            multipleSelection: [],
            search: ''
        }
    },
    mounted(){
    },
    methods: {
        searchEmail(){
            let apiData = {
                email: this.search
            }
            membersList({apiData}).then( res => {
                this.gridData = res.data;
            })
        },
        toggleSelection(rows) {
            let _this =this 
            if (rows) {
                rows.forEach(row => {
                    this.$nextTick().then(function () {
                        _this.$refs.multipleTable.toggleRowSelection(row);
                    }) 
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        openUserDialog(){
            this.dialogTableVisible = true;
            //this.toggleSelection([this.gridData[1]]);
        },
        confirm(){
            this.$emit('userList', this.multipleSelection);
            this.dialogTableVisible = false;
        }
    }
}
</script>

<style>
.input-border .el-input__inner{
    border-color: #6200EE;
}
</style>