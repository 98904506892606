<template>
  <div class="cert-details">
    <el-row :gutter="20" style="margin-bottom: 15px;">
      <el-col :span="10">
        <el-card shadow="always" style="height: 100%;">
          <template #header>
            <div class="card-header">
              <span>{{ $t('data.template.certificateDetail') }}</span>
            </div>
          </template>
          <div>
            <el-form ref="form" :model="form" :disabled="isDisabled">
              <el-form-item>
                <el-input v-model="form.topic" :placeholder="$t('data.template.eCertTopic')"></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.group_id" :placeholder="$t('data.template.group')">
                  <el-option v-for="(item, index) in groupList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.category_id" :placeholder="$t('data.template.eCertCatagory')">
                  <el-option v-for="(item, index) in catagoryList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div align="right" class="mt-20">
              <el-button type="primary" @click="openNewGroup">{{ $t('data.template.createNewGroupHere') }}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="14">
        <el-card shadow="always">
          <template #header>
            <div class="card-header">
              <span>{{ $t('data.template.approveMember') }}</span>
            </div>
          </template>
          <div class="add-member">
            <div align="center">
              <search-user @userList="userMember"></search-user>
              <el-upload
                style="margin-left: 15px; display: inline-block;"
                class="upload-demo"
                action="#"
                :on-change="handleChange"
                :show-file-list=false>
                <el-button type="primary">{{ $t('data.template.uploadSpreadsheet') }}</el-button>
              </el-upload>
            </div>
            <el-table
              :data="tableData"
              max-height="220"
              style="width: 100%">
              <el-table-column
                prop="name"
                :label="$t('data.template.table.userName')">
              </el-table-column>
              <el-table-column
                prop="email"
                :label="$t('data.template.table.eMail')">
              </el-table-column>
              <el-table-column
                fixed="right"
                :label="$t('data.template.table.actions')"
                width="120">
                <template #default="scope">
                  <el-button
                    @click.prevent="deleteRow(scope.$index, tableData)"
                    type="text"
                    size="small">
                    {{ $t('data.public.remove') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div align="center" class="mt-20">
              <el-button @click="resetUserList()">{{ $t('data.public.reset') }}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>

    </el-row>


    <el-row :gutter="20">

      <el-col :span="10">
        <el-card shadow="always">
          
          <template #header>
            <div class="card-header">
              <span>{{ $t('data.template.createeCert') }}</span>
              <el-button class="button" type="text" @click="downloadDesign"><i class="el-icon-download"></i> {{ $t('data.template.downloadDesign') }}</el-button>

            </div>
          </template>

          

          <el-form ref="certContent" :model="certContent" :disabled="isDisabled">
            <div class="mb-20">
              <h4 class="mb-20">{{ $t('data.template.certificateContent') }}</h4>
              <image-upload :btnText="$t('data.template.uploadDesign')" @imageFile="imageFile"></image-upload>
              <image-upload :btnText="$t('data.template.uploadLeftLogo')" @imageFile="imageFile"></image-upload>
              <image-upload :btnText="$t('data.template.uploadMiddleLogo')" @imageFile="imageFile"></image-upload>
              <bg-default @imageDefault="imageDefault"></bg-default>
            </div>
            <el-form-item>
              <el-radio-group v-model="certContent.text_align">
                <el-radio-button label="left">{{ $t('data.template.left') }}</el-radio-button>
                <el-radio-button label="center">{{ $t('data.template.center') }}</el-radio-button>
                <el-radio-button label="right">{{ $t('data.template.right') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-input v-model="certContent.course" :placeholder="$t('data.template.certificateCourse')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="certContent.cert_name" :placeholder="$t('data.template.certificateName')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="certContent.sub_title" :placeholder="$t('data.template.certificateSubTitle')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('data.template.showPrintedName')">
              <el-switch v-model="certContent.show_printed_name"></el-switch>
            </el-form-item>
            <el-form-item :label="$t('data.template.showDateBirth')">
              <el-switch v-model="certContent.show_date"></el-switch>
            </el-form-item>
            <el-form-item :label="$t('data.template.showNationality')">
              <el-switch v-model="certContent.show_nationality"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="certContent.description_1" :placeholder="$t('data.template.certificateDescription1')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-date-picker type="date" :placeholder="$t('data.template.eCertIssueDate')" v-model="certContent.issue_date" @change="issueConfirmDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="certContent.description_2" :placeholder="$t('data.template.certificateDescription2')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-date-picker type="date" :placeholder="$t('data.template.eCertExpiryDate')" v-model="certContent.expiry_date" @change="validConfirmDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="certContent.description_3" :placeholder="$t('data.template.certificateDescription3')"></el-input>
            </el-form-item>

            <image-upload :btnText="$t('data.template.leftSign')" @imageFile="imageFile"></image-upload>
            <el-form-item>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_left_name" :placeholder="$t('data.template.name')"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_left_title" :placeholder="$t('data.template.title')"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_left_organization " :placeholder="$t('data.template.organization')"></el-input>
                </el-col>
              </el-row>
            </el-form-item>

            <image-upload :btnText="$t('data.template.centerSign')" @imageFile="imageFile"></image-upload>
            <el-form-item>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_center_name" :placeholder="$t('data.template.name')"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_center_title" :placeholder="$t('data.template.title')"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_center_organization" :placeholder="$t('data.template.organization')"></el-input>
                </el-col>
              </el-row>
            </el-form-item>

            <image-upload :btnText="$t('data.template.rightSign')" @imageFile="imageFile"></image-upload>
            <el-form-item>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_right_name" :placeholder="$t('data.template.name')"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_right_title" :placeholder="$t('data.template.title')"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input v-model="certContent.bottom_right_organization " :placeholder="$t('data.template.organization')"></el-input>
                </el-col>
              </el-row>
            </el-form-item>

          </el-form>

          <el-button v-print="'#certView'" v-html="$t('data.template.previewPrint')"></el-button>

        </el-card>
      </el-col>

      <el-col :span="14">
        <div id="certView">

          <template-cert :certData="certContent"></template-cert>

        </div>

      </el-col>

    </el-row>

    <el-row :gutter="20">
      <el-col :span="24">
        <div class="cert-issues">
          <h3 class="mb-10">{{ $t('data.template.numberIssuesRemainBranch') }}: {{ canIssueLimit }} {{ $t('data.template.issues') }}</h3>
          <p>{{ $t('data.template.preApprovedeCert') }}：{{ approvedNumbers }}</p>
          <div class="issues-select">
              <p>{{ $t('data.template.acceptPublicApplicant') }}：</p>
              <el-input v-model.number="issueNumbers" placeholder="Please Input"></el-input>
          </div>
          <p class="mb-20">{{ $t('data.template.issueeCertsNow') }}: {{ approvedNumbers + issueNumbers}}</p>

          <div class="issues-prompt">
            <p>
              {{ $t('data.template.publishText1') }}<br/>
              {{ $t('data.template.publishText2') }}
            </p>
          </div>


          <div class="issues-btn">
            <el-button type="primary"  @click="ConfirmIssuePopup = true">{{ $t('data.public.publish') }}</el-button>
            <el-button @click="handleSave">{{ $t('data.public.save') }}</el-button>
          </div>

        </div>
      </el-col>
    </el-row>
    <el-dialog
      :title="$t('data.template.confirmIssuePopup')"
      v-model="ConfirmIssuePopup"
      width="30%">
      <span>{{$t('data.template.ConfirmIssue')}} {{ approvedNumbers + issueNumbers}} {{$t('data.template.certsNow')}}?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ConfirmIssuePopup = false">{{ $t('data.public.cancel') }}</el-button>
          <el-button type="primary" @click="submitForm()">{{ $t('data.public.confirm') }}</el-button>
          <!-- <router-link to='/ecert/detail/123' style="margin-left:15px"><el-button type="primary">OK</el-button></router-link> -->
        </span>
      </template>

    </el-dialog>

    <grouo-dialog :groupData="groupData"></grouo-dialog>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import ImageUpload from '../../components/imageUpload/ImageUpload'
import BgDefault from './component/BgDefault.vue'
import SearchUser from './component/SearchUser.vue'
import TemplateCert from '../../components/templateCert/TemplateCert'
import grouoDialog from "./component/groupDialog.vue"
import { certGroups,getCertCategories,newCertsTemplate,certsTemplateEdit,certsTemplateDetails,certsTemplateIssue,batchIssues,memberImportSearch,getBase,userBranch } from '../../api/index'
import {ref} from 'vue'
export default {
  name: 'certDetails',
  data(){
    return{
      groupList:[],
      catagoryList: [],
      form: {
        topic: '',
        group_id: null,
        category_id: null
      },
      certContent: {
        text_align: 'center',
        course: 'Enter the text related to the certificate.',
        cert_name: 'Certificate Name',
        sub_title: 'This certificate is proudly presented to',
        show_printed_name: true,
        show_date: true,
        show_nationality: false,
        description_1: 'This is a sample text. Insert your desired text here.This is a sample text. Insert your desired text here. on the date of',
        issue_date: '',
        description_2: 'This is a sample text. Insert your desired text here. Expiry date on',
        expiry_date: '',
        description_3: 'This is a sample text. Insert your desired text here.This is a sample text. Insert your desired text here.',
        bottom_left_name: '',
        bottom_left_title: '',
        bottom_left_organization: '',
        bottom_center_name: '',
        bottom_center_title: '',
        bottom_center_organization: '',
        bottom_right_name: '',
        bottom_right_title: '',
        bottom_right_organization: '',
        background: '',
        top_left_logo: '',
        top_center_logo: '',
        bottom_left_logo: '',
        bottom_center_logo: '',
        bottom_right_logo: '',
        qrcode: '/image/qr-code.jpg',
        printList: [{
            name: 'Printed Name'
        }]
      },
      issueNumbers: 0,
      approvedNumbers: 0,
      canIssueLimit: 0,
      tableData: [],
      ConfirmIssuePopup: false,
      groupData: {
          state: false
      },
      isDisabled: false,
      issueLoading: false
    }
  },
  setup(){
  },

  components: {
    ImageUpload,
    TemplateCert,
    BgDefault,
    SearchUser,
    grouoDialog
  },
  computed: {
    // isDisabled(){
    //   return this.$route.params.id ? true : false;
    // }
  },
  mounted(){
    this.initPageData();
  },
  methods:{
    initPageData(){
          let apiData = {}
          certGroups({apiData}).then( res => {
             this.groupList = res.data;
          })
          getCertCategories({apiData}).then( res => {
            this.catagoryList = res.data;
          })
          userBranch({}).then( res => {
            this.canIssueLimit = res.data.can_issue_limit;
          })
          if(this.$route.params.id){
            apiData = {
              id: this.$route.params.id
            }
            certsTemplateDetails({apiData}).then( res => {
              if(res.data.completed_application === 0){
                this.isDisabled = false;
              } else {
                this.isDisabled = true;
              }
              this.certContent = Object.assign(this.certContent,res.data);
              this.form.topic = res.data.topic;
              this.form.group_id = res.data.group.id;
              this.form.category_id = res.data.category.id;
            })
          }
    },
    imageFile(imageFile){
      if(imageFile.text === this.$t('data.template.uploadDesign')){
        this.certContent.background = imageFile.url;
        return;
      } else if(imageFile.text === this.$t('data.template.uploadLeftLogo')){
        this.certContent.top_left_logo = imageFile.url;
        return;
      } else if(imageFile.text === this.$t('data.template.uploadMiddleLogo')){
        this.certContent.top_center_logo = imageFile.url;
        return;
      } else if(imageFile.text === this.$t('data.template.leftSign')){
        this.certContent.bottom_left_logo = imageFile.url;
        return;
      } else if(imageFile.text === this.$t('data.template.centerSign')){
        this.certContent.bottom_center_logo = imageFile.url;
        return;
      } else if(imageFile.text === this.$t('data.template.rightSign')){
        this.certContent.bottom_right_logo = imageFile.url;
        return;
      }
    },
    imageDefault(src){
      this.certContent.background = src;
      return;
    },
    deleteRow(index, rows){
      rows.splice(index, 1);
    },
    handleChange(files, fileList) {
      let param = new FormData();
      param.append('excel', files.raw)
      let apiData = param
      memberImportSearch({apiData}).then( res => {
        this.tableData = res.data;
        this.approvedNumbers = this.tableData.length;
      })
    },
    issueConfirmDate(value){
      if(value){
        this.certContent.issue_date = dayjs(value).format('YYYY-MM-DD');
      }
    },
    validConfirmDate(value){
      if(value){
        this.certContent.expiry_date = dayjs(value).format('YYYY-MM-DD');
      }
    },
    userMember(data){
      this.tableData = this.tableData.concat(data);
      let obj = {};
      let peon = this.tableData.reduce((cur,next) => {
          obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
          return cur;
      },[]);
      this.tableData = peon;
      this.approvedNumbers = this.tableData.length;
    },
    resetUserList(){
      this.tableData = [];
    },
    openNewGroup(){
      this.groupData.saveType = 'create'
      this.groupData.state = true;
    },
    submitForm(){
      this.ConfirmIssuePopup = false;
      if(this.isDisabled){
        this.eCertIssue(this.$route.params.id);
      }else if(this.$route.params.id){
        Object.assign(this.certContent,this.form);
        let apiData = this.certContent;
        apiData.id = this.$route.params.id;
        certsTemplateEdit({apiData}).then( res => {
          this.eCertIssue(this.$route.params.id);
        })
      } else {
        Object.assign(this.certContent,this.form);
        let apiData = this.certContent
        newCertsTemplate({apiData}).then( res => {
          this.eCertIssue(res.data.id);
        })
      }
    },
    eCertIssue(val){
      let apiData = {
        id: val,
        member_ids: [],
        public_applicant_limit: this.issueNumbers
      }
      this.tableData.forEach(item => {
        apiData.member_ids.push(item.id);
      });
      certsTemplateIssue({apiData}).then( res => {
        if(res.data.batch_issue){
          let obj = {
            batch_issue_id: res.data.batch_issue.id,
            params_id: val
          }
          this.issueTaskDetails(obj)
        }
      })
      // if(this.tableData[0]){
        
      // } else {
      //     this.$router.push('/ecert/detail/'+val);
      // }
        
    },
    handleSave(){
      if(this.$route.params.id){
        Object.assign(this.certContent,this.form);
        let apiData = this.certContent;
        apiData.id = this.$route.params.id;
        // this.tableData.forEach(item => {
        //   apiData.member_ids.push(item.id);
        // });
        certsTemplateEdit({apiData}).then( res => {
            this.ConfirmIssuePopup = false;
            ElMessage.success({
                message: 'success',
                type: 'success'
            });
        })
      } else {
        Object.assign(this.certContent,this.form);
        let apiData = this.certContent
        newCertsTemplate({apiData}).then( res => {
          this.$router.push('/ecert/detail/'+res.data.id);
        })
      }
    },
    issueTaskDetails(obj){
      let apiData = {
        id: obj.batch_issue_id
      }
      let options = {
        background: 'rgba(255,255,255,1)'
      };
      batchIssues({apiData}).then( res => {
          if(res.data.status === 2){
            ElLoading.service(options).close();
            window.location.href = '/ecert/detail/'+obj.params_id;
          } else{
            ElLoading.service(options);
            let _this = this;
            setTimeout(function(){
              let objSub = {
                batch_issue_id: res.data.id,
                params_id: obj.params_id
              }
              _this.issueTaskDetails(objSub)
            },5000);
          }
      })
    },
    downloadDesign(){
      getBase({}).then( res => {
          if(res.data.design_specification){
            window.open(res.data.design_specification);
          }
        })
    }
    
  }
}
</script>



<style scoped>
.el-select{
  width: 100%;
}

.mb-20{
  margin-bottom: 20px;
}
.cert-issues{
  padding: 30px 0px;
  text-align: center;
}
.issues-select{
    display: flex;
    justify-content: center;
    margin: 10px 0px;
}
.issues-select p{
  height: 40px;
  line-height: 40px;
}
.issues-select .el-input{
  width: 150px;
  margin: 0px;
}
.issues-btn{
  margin-top: 15px;
}
.issues-prompt p{
  line-height: 26px;
  color: #224486;
  font-size: 14px;
}
</style>